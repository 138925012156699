import quizQ1 from 'assets/images/event/240924/quiz-q1.png';
import quizQ2 from 'assets/images/event/240924/quiz-q2.png';
import quizQ3 from 'assets/images/event/240924/quiz-q3.png';
import quizQ4 from 'assets/images/event/240924/quiz-q4.png';
import quizQ5 from 'assets/images/event/240924/quiz-q5.png';
import quizQ6 from 'assets/images/event/240924/quiz-q6.png';
import quizQ7 from 'assets/images/event/240924/quiz-q7.png';
import quizQ8 from 'assets/images/event/240924/quiz-q8.png';
import quizQ9 from 'assets/images/event/240924/quiz-q9.png';
import quizQ10 from 'assets/images/event/240924/quiz-q10.png';
import quizQ11 from 'assets/images/event/240924/quiz-q11.png';
import quizQ12 from 'assets/images/event/240924/quiz-q12.png';
import quizQ13 from 'assets/images/event/240924/quiz-q13.png';
import quizQ14 from 'assets/images/event/240924/quiz-q14.png';
import quizQ15 from 'assets/images/event/240924/quiz-q15.png';
import quizQ16 from 'assets/images/event/240924/quiz-q16.png';
import quizQ17 from 'assets/images/event/240924/quiz-q17.png';
import quizQ18 from 'assets/images/event/240924/quiz-q18.png';
import quizQ19 from 'assets/images/event/240924/quiz-q19.png';
import quizQ20 from 'assets/images/event/240924/quiz-q20.png';

const quizData = [

  {
    id: 1,
    title: `순서에 <span>맞게</span> 쓴 것을<br class="brMob"> 고르세요.`,
    examples: ["A", "B"],
    answer: "A",
    src:quizQ1,
    gridType: 2
  },
  {
    id: 2,
    title: `순서에 <span>맞게</span> 쓴 것을<br class="brMob"> 고르세요.`,
    examples: ["A", "B"],
    answer: "B",
    src:quizQ2,
    gridType: 2
  },
  {
    id: 3,
    title: `그림을 보고 빈칸에<br class="brMob"> <span>알맞은 말</span>을 고르세요.`,
    examples: ["미", "중", "일", "국"],
    answer: "국",
    src:quizQ3,
    gridType: 2
  },
  {
    id: 4,
    title: `그림을 보고 빈칸에<br class="brMob"> <span>알맞은 말</span>을 고르세요.`,
    examples: ["김", "치", "개", "게"],
    answer: "개",
    src:quizQ4,
    gridType: 2
  },
  {
    id: 5,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    examples: ["치마", "바지", "셔츠", "재킷"],
    answer: "치마",
    src:quizQ5,
    gridType: 2
  },
  {
    id: 6,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    examples: ["물", "주스", "녹차", "커피"],
    answer: "커피",
    src:quizQ6,
    gridType: 2
  },
  {
    id: 7,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    src:quizQ7,
    examples: ["오이", "양파", "당근", "양상추"],
    answer: "양파",
    gridType: 2
  },
  {
    id: 8,
    title: `(<Fragment>&ensp;&ensp;&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brPc"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ8,
    examples: ["시장", "공항", "병원", "회사"],
    answer: "회사",
    gridType: 2
  },
  {
    id: 9,
    title: `(<Fragment>&ensp;&ensp;&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brPc"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ9,
    examples: ["극장", "은행", "학교", "공원"],
    answer: "극장",
    gridType: 2
  },
  {
    id: 10,
    title: `(<Fragment>&ensp;&ensp;&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brPc"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ10,
    examples: ["아픕니다", "고픕니다", "덥습니다", "예쁩니다"],
    answer: "고픕니다",
    gridType: 2
  },
  {
    id: 11,
    title: `(<Fragment>&ensp;&ensp;&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brPc"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ11,
    examples: ["뺐습니다", "입습니다", "썼습니다", "꼈습니다"],
    answer: "꼈습니다",
    gridType: 2
  },
  {
    id: 12,
    title: `(<Fragment>&ensp;&ensp;&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brPc"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ12,
    examples: ["별로", "매우", "빨리", "아직"],
    answer: "매우",
    gridType: 2
  },
  {
    id: 13,
    title: `다음은 무엇에 대한<br class="brMob"> 내용입니까?`,
    src:quizQ13,
    examples: ["나이", "직업", "취미", "약속"],
    answer: "직업",
    gridType: 2
  },
  {
    id: 14,
    title: `다음은 무엇에 대한<br class="brMob"> 내용입니까?`,
    src:quizQ14,
    examples: ["학교", "서점", "도서관", "백화점"],
    answer: "도서관",
    gridType: 2
  },
  {
    id: 15,
    title: `다음은 무엇에 대한<br class="brMob"> 내용입니까?`,
    src:quizQ15,
    examples: ["시간", "약속", "운동", "요일"],
    answer: "운동",
    gridType: 2
  },
  {
    id: 16,
    title: `다음을 읽고 이어지는 말을 고르십시오.`,
    src:quizQ16,
    examples: ["네, 알겠습니다.", "네, 고맙습니다.", "네, 괜찮습니다.", "네, 처음 뵙겠습니다."],
    answer: "네, 처음 뵙겠습니다.",
    gridType: 1
  },
  {
    id: 17,
    title: `다음을 읽고 이어지는 말을 고르십시오.`,
    src:quizQ17,
    examples: ["반갑습니다.", "실례합니다.", "잘 먹었습니다", "잘 먹겠습니다."],
    answer: "잘 먹겠습니다.",
    gridType: 1
  },
  {
    id: 18,
    title: `다음을 읽고 이어지는 말을 고르십시오.`,
    src:quizQ18,
    examples: ["아니에요.", "고마워요.", "미안합니다.", "오랜만이에요."],
    answer: "아니에요.",
    gridType: 1
  },
  {
    id: 19,
    title: `다음을 읽고 물음에<br class="brMob"> <span>알맞은</span> 말을 고르세요.`,
    src:quizQ19,
    examples: ["작년에 왔어요.", "작년에 올 거예요.", "내년에 왔어요.", "내년에 올 거예요."],
    answer: "작년에 왔어요.",
    gridType: 1
  },
  {
    id: 20,
    title: `다음을 읽고 물음에<br class="brMob"> <span>알맞은</span> 말을 고르세요.`,
    src:quizQ20,
    examples: ["나중에 먹을 거예요.", "집에서 먹을 거예요.", "내년에 왔어요.", "친구하고 먹을 거예요."],
    answer: "집에서 먹을 거예요.",
    gridType: 1
  },
];

export default quizData;