import React, { useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ErrorPage from 'component/atoms/Error';
//import KoreanSong from './20211008KoreanSong/index';
//import KoreanSongBest from './20211102KoreanSongBest/index';
import AiSpeakEvt from './20211119AiSpeak/index';
//import MockTestEvent from './20220103MockTest/index';
import AliveEvent from './20220321Alive/index';
import PromotionMockTest from './20220512MockTest/index';
import EventAliveKorean from './20220609AliveFree/index';
// import EventTopikNote from './20220617TopikNote/index';
import EventTopikNote from './20230612TopikNote/index';
import TopikCompetition from './20220103MockTest/index';
import SpecialLecture from './20220714SpecialLecture/index';
import VideoClass from './20220728VideoClass/index';
import TopikChallenge from './20220829TopikChallenge/index';
import Signup from './20230802Signup/index';
import AiSpeakEvent from './20221006AiSpeak/index';
import BookSale from './20221130BookSale/index';
import MonthPass from './20230103MonthPass/index';
import FreeTrial from './20230203FreeTrial/index';
import Topik from './20230310Topik/index';
import FreeTrialKorean from './20230515FreeTrial/index';
import FreeTrialTopik from './20230609FreeTrial/index';
import EventKorean from './20230920korean/index';
import HdKorean from './20240924HdKorean/index';



export { EventAliveKorean };

const EventPageRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route exact path={`${path}/mtalive`} component={AliveEvent} /> */}
      <Route exact path={`${path}/mocktest`} component={PromotionMockTest} />
      <Route exact path={`${path}/topiknote`} component={EventTopikNote} />
      {/* <Route exact path={`${path}/alivefree`} component={EventAliveKorean} /> */}
      <Route
        exact
        path={`${path}/topik-competition-2nd`}
        component={TopikCompetition}
      />
      {/* <Route exact path={`${path}/special`} component={SpecialLecture} /> */}
      <Route exact path={`${path}/mtalive`} component={VideoClass} />
      <Route exact path={`${path}/TopikChallenge`} component={TopikChallenge} />
      <Route exact path={`${path}/AiSpeak`} component={AiSpeakEvent} />
      <Route path={`${path}/signup`} component={Signup} />
      <Route path={`${path}/BookSale`} component={BookSale} />
      <Route path={`${path}/MonthPass`} component={MonthPass} />
      <Route path={`${path}/FreeTrial`} component={FreeTrial} />
      <Route path={`${path}/Topik`} component={Topik} />
      <Route path={`${path}/FreeTrialKorean`} component={FreeTrialKorean} />
      <Route path={`${path}/FreeTrialTopik`} component={FreeTrialTopik} />
      <Route path={`${path}/korean`} component={EventKorean} />
      <Route path={`${path}/HdKorean`} component={HdKorean} />

      <Route path={`${path}`} component={ErrorPage} />
    </Switch>
  );
};
export default EventPageRouter;
