import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import VideoInfo from './VideoInfo';
import CourseViewer from 'container/CourseViewPage/Contents/CourseViewer';
import CourseViewContentsTab from './Tab';
import contentsApi from 'api/contents';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAxios } from 'common/hook/useAxiosHook';
import productApi from 'api/product';

const Container = styled.div`
  padding: 3em;
`;

/**
 * course view 왼쪽 영역
 */
const CourseViewContents = React.forwardRef((props, playerRef) => {

  const token = useSelector((state) => state.user.token);
  const { productId, lectureId } = useParams();

  const [prevLectureId, setPrevLectureId] = useState();
  const [nextLectureId, setNextLectureId] = useState();

  const [{ data: postLogData }, fetchPostLog] = useAxios(contentsApi.postLogApi, [], true);
  const [{ data: productData }, fetchProductData] = useAxios(
    () => productApi.getCourseOfProductDetail(productId, token),
    [],
    true
  );

  useEffect(() => {
    fetchProductData(productId, token);
    if (parseInt(lectureId) === 0) return;
    fetchPostLog(productId, lectureId, token);
    //return () => {};
  }, [fetchPostLog, lectureId, productId, token]);

  useEffect(() => {
    if (productData) {
      var prev = 0;
      var cur = 0;
      var next = 0;
      var isFound = false;
      var isEnded = false;

      for (let i = 0; i < productData.length; i++) {
        const chapter = productData[i].lessonResponseList;
        for (let j = 0; j < chapter.length; j++) {
          const current = chapter[j];
          if (isFound) {
            next = current.chapterLectureId;
            isEnded = true;
            break;
          } else {
            //if (`${current.chapterLectureId}` === lectureId) {
            if (current.chapterLectureId == lectureId) {
              isFound = true;
            }
            prev = cur;
            cur = current.chapterLectureId;
          }
        }

        if (isEnded) break;
      }

      if(next === 0){
        // 다음 강좌가 OT 일 경우 처리
        next = productData[0].lessonResponseList[0].chapterLectureId;
      }

      setPrevLectureId(prev);
      setNextLectureId(next);
    }
  }, [productData])

  return (
    <>
      <CourseViewer
        ref={playerRef}
        postLogData={postLogData}
        nextLectureId={nextLectureId}/>

      <Container>
        <VideoInfo
          playerRef={playerRef}
          postLogData={postLogData}
          prevLectureId={prevLectureId}
          nextLectureId={nextLectureId} />

        <CourseViewContentsTab />
      </Container>
    </>
  );
});

export default CourseViewContents;
