import React, { useEffect, useState } from 'react';
import Styled from './index.style';

import { useHistory, useLocation } from 'react-router-dom';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";



//import Img from 'component/atoms/Img';

import character from 'assets/images/event/240924/character.png';
import title from 'assets/images/event/240924/mainTitle.png';
import title_bg from 'assets/images/event/240924/step3_title_icon.png';
import closeIcon from 'assets/images/event/240924/close-icon.png';
import vIcon from 'assets/images/event/240924/v.png';
//import thumbnailDefault from 'assets/images/thumb_default_course.jpg';

import quizzes from 'container/EventPage/20240924HdKorean/QuizData';
import copy from 'copy-to-clipboard';
import { useAxios } from 'common/hook/useAxiosHook';
import coursesApi from 'api/courses';


import { actions } from 'component/user/state';
import ConfirmModal from "../ConfirmModal";
import {openLink} from "../../../common/util/util";
import {useTranslation} from "react-i18next";
import contentsApi from "../../../api/contents";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";



const Step2Content = ({ page, currentQuiz, moveToNext }) => {
  const currentQuestion = currentQuiz;

  return (
    <div className={`step2-con step2-con${currentQuestion?.id}`}>
      <div className='step2-con-head'>
        <h5>Question {currentQuestion?.id}.</h5>
        <h3 dangerouslySetInnerHTML={{ __html: currentQuestion?.title }}></h3>
        <p>{currentQuestion?.titleEn}</p>
      </div>
      <div className='step2-con-body'>
        <div className={currentQuestion?.id === 1 ? "img2" : currentQuestion?.id === 2 ? "img2" :"img"}>
          <img src={currentQuestion.src} alt="이미지" />
        </div>
        <div className='answerBox'>
        <div className={currentQuestion?.gridType === 1 ? "examples" : "examples2"}>
          {currentQuestion?.examples &&
            currentQuestion?.examples.map((example, idx2) => (
              <button key={idx2} onClick={() => moveToNext(example)} className={`ex${idx2} ${page === 20 ? 'large' : ''}`}>
                {example}
              </button>
            ))}
        </div>
        </div>
      </div>
    </div>
  );
};

const EventPage = () => {
  const { t } = useTranslation('event/20240924HdKorean');
  const language = localStorage.getItem('i18nextLng');

  const isLogin = useLoginStatus();
  const token = useSelector((state) => state.user.token);

  let { handleModal } = React.useContext(ModalContext);
  const location = useLocation();
  const history = useHistory();


  const [page, setPage] = useState(1); // 스탭2 page 번호
  const [step, setStep] = useState(1); // 스텝 번호
  const [correctAnswers, setCorrectAnswers] = useState([]); // 선택 정오답
  const [score, setScore] = useState(0); //총 점수
  const [levelNumber, setLevelNumber] = useState(0); //level
  const [copyModal, setCopyModal] = useState(false); //총 점수



  const [selScore, fetchUpdateScoreResponse] = useAxios(
      coursesApi.updateScoreApi,
      [],
      true
  );

  const [ {data: checkResult},fetchAuthResultResponse] = useAxios(
      coursesApi.getAuthResult,
      [],
      true
  );

  const levelType = {
    begginer: "Begginer",
    intermediate: "Intermediate",
    advanced: "Advanced"
  }

  const level = (score >= 70) ? levelType.advanced : (score >= 40) ? levelType.intermediate : levelType.begginer;



  const checkLogin = (e) => {
    if (!isLogin) {
      e.preventDefault();
      handleModal(
          <ConfirmModal
              tMessage={t('notLogin.title')}
              tButton={t('notLogin.confirm')}
              afterClose={() =>
                  handleModal(<Login />, false, 420, location.pathname)
              }
          />
      );

      return;
    } else {
      //사용자 체크
      if(checkResult.code == '0'){
        startQuiz();
      }else{
        alert('You have already participated in the event.');
      }
    }
  };


  const startQuiz = () => {
    setStep(2);
  };

  const resetStart = () => {
    setCorrectAnswers([]);
    setScore(0);
    setStep(1);
    setPage(1);
  };


  const closeModal = () => {
    setCopyModal(false);
  };


  const moveToNext = (example) => {
    if (example === quizzes[page - 1].answer) {
      setScore((prev) => prev + 5);
    }

    setCorrectAnswers((prevCorrectAnswers) => [...prevCorrectAnswers, {
      choice: example,
      answer: quizzes[page - 1].answer,
      correct: example === quizzes[page - 1].answer // 정답이면 true , 오답이면 false
    }]);

    if (quizzes.length > page) {
      setPage((prev) => prev + 1);
    } else {
      if(checkResult.code == '0'){
        fetchUpdateScoreResponse(score,levelNumber,token);
      }else{
        alert('You have already participated in the event.');
      }
      setStep(3);
    }
  };

  useEffect(() => {
    const root = document.getElementById('root');
    root.style = `background: #124e4f;`;
    return () => {
      root.style = `background: none;`;
    };
  }, []);

  useEffect(() => {
    if (step === 3) {
      //TODO. 추천 강좌 검색
      setLevelNumber((level === levelType.begginer) ? 1 : (level === levelType.intermediate) ? 2 : 3);

    }
  }, [step]);


  useEffect(() => {
    fetchAuthResultResponse(token);
  },[fetchAuthResultResponse]);





  return (
    <>
      <Styled.EventWrap>
        {step === 1 ?
          <Styled.Step1>
            <div className='step1-wrap'>
              <h1><img src={title} alt="10.09 한글데이" /></h1>
              <div className="character-wrap">
                <div className='character'><button className='btn-takeTest' onClick={checkLogin}><img src={character} alt="TAKE TEST" /></button></div>
              </div>
            </div>
          </Styled.Step1>
          : step === 2 ?
            <Styled.Step2>
              <div className='step2-wrap'>
                <Step2Content page={page} currentQuiz={quizzes[page - 1]} moveToNext={moveToNext} />
                <div className='step2-foot'>
                  <button onClick={resetStart}>HOME</button>
                  <div className='paging'>{page}/20</div>
                </div>
              </div>
            </Styled.Step2>
            : <Styled.Step3>
                  <div className='step3-wrap'>
                    <div className='step3-head'>
                      <h3><img src={title_bg} alt="무늬"/>Your Korean Score <img src={title_bg} alt="무늬"/></h3>
                      <div className='score-wrap'>
                        <h5>Points</h5>
                        <h2>{score}</h2>
                        <p>
                          You are on the {level} level. <br/>
                          Check which problem you got wrong.
                        </p>
                      </div>
                    </div>
                    <div className='step3-body'>
                      {copyModal && <div className='copy-modal'>
                        <button className='close-btn' onClick={closeModal}><img src={closeIcon} alt="닫기"/></button>
                        <h5>Event URL has been copied</h5>
                        <h3>
                          <span>Share</span> the <span>event</span><br/>
                          with your friends.
                        </h3>
                        <button className='check-btn' onClick={closeModal}>Check!</button>
                      </div>
                      }
                      <div className='step3-table'>
                        <ul>
                          {step === 3 && correctAnswers.map((list, idx) => {
                            const {choice, answer, correct} = list

                            return (
                                <li key={idx} className={`${correct ? 'anwser' : 'wrong'}`}>
                                  <span className='t-head'>{idx + 1}</span>
                                  <span className='t-body'>
                              <span className='choice'>{choice}</span>
                              <span className='wrong-answer'>{answer}</span>
                            </span>
                                </li>
                            )

                          })}

                        </ul>
                      </div>
                    </div>
                    <div className='step3-foot'>
                      <div className='read-more'></div>
                      <div className='read-more'></div>
                    </div>


                    </div>
                </Styled.Step3>
        }
      </Styled.EventWrap>
    </>
  );
};

export default EventPage;